.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

      * { margin: 0; padding: 0; box-sizing: border-box; }
      body { margin: 0; padding: 0; background: #f5f5f5; font-family: 'Poppins', sans-serif;}

      .review-wrap {
          width: 100%;
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 40px;
      }

      .review-box {
          width: 100%;
          max-width: 650px;
          background: #fff;
          padding: 50px;
          border: 1px solid #dfdfdf;
          border-radius: 14px;
      }

      .review-box h2 {
          width: 100%;
          text-align: center;
          font-size: 28px;
          font-weight: 700;
          color: #d13729;
          line-height: 42px;
      }

      .review-box p {
          width: 100%;
          text-align: center;
          font-size: 16px;
          color: #222;
      }

      .review-smiley {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 30px;
          justify-content: center;
      }

      .review-smiley a img {
          width: 100px;
      }

      .review-smiley a {
          margin: 0 10px;
      }
        
      .logo {
        width: 100%;
        text-align: center;
    }
    .review-box h2 {
      width: 100%;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      color: #222;
      line-height: 42px;
  }
  .comments-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.comments-input label {
    font-size: 13px;
}

.comments-input textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 90px;
    margin-top: 5px;
}

.comments-input button {
    width: 100%;
    margin-top: 10px;
    background: #d13729;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 10px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.logo {
    width: 100%;
    text-align: center;
}